import React from "react";
import Coupon from "../Coupon";

import { Props } from "./types";

import "./styles.css";

const CouponsList = (props: Props) => (
  <section className="Coupons">
    {props.coupons.map((coupon, idx) => (
      <Coupon
        key={idx}
        coupon={coupon}
        selected={props.selectedCoupon && coupon.id === props.selectedCoupon.id}
        onSelect={props.onSelect}
      />
    ))}
  </section>
);

export default CouponsList;
