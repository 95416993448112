import React from "react";

import { Props } from "./types";
import "./styles.css";

const CouponsSuccess = (props: Props) => {
  const {
    coupon = {
      image_url: "",
      short_title: "",
      description: "",
      legal_description: ""
    },
    onBack
  } = props;

  return (
    <section className="Success">
      <h1 className="Success-title" style={{ color: props.totem.color }}>
        TU DESCUENTO
      </h1>
      <div className="Success-coupon">
        <div className="Success-image">
          <img src={coupon.image_url} alt={coupon.description} />
        </div>
        <h1 className="Success-subtitle">{coupon.short_title}</h1>
        <div className="Success-text">{coupon.legal_description}</div>
        <div className="Success-explain">
          ¡Ahora revisa tu correo para disfrutar tu descuento!
        </div>
        <button
          className="Success-back"
          style={{ backgroundColor: props.totem.color }}
          onClick={onBack}
        >
          VOLVER
        </button>
      </div>
    </section>
  );
};

export default CouponsSuccess;
