import React from "react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { validate, clean } from "rut.js";

import { Props } from "./types";
import { UserType } from "../App/types";

const BaseForm = (props: Props) => {
  const { register, handleSubmit, errors } = useForm<UserType>();

  const onSubmit: Props["onSubmit"] = (data) => {
    data.rut = clean(data.rut);
    props.onSubmit(data);
  };

  const baseClass = props.baseClass || "Form";

  return (
    <section className={baseClass}>
      <div className={`${baseClass}-content`}>
        <div className={`${baseClass}-title`}>
          Ingresa tus datos para enviar los descuentos seleccionados a tu e-mail
        </div>
        {props.loading && <span>Cargando...</span>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            name="rut"
            placeholder="RUT"
            defaultValue={props.user && props.user.rut}
            className={classnames("Form-input", {
              error: !!errors.rut,
              disabled: props.loading,
            })}
            ref={register({
              required: true,
              validate: (value) => validate(value),
            })}
            disabled={props.loading}
          />
          <div className={`${baseClass}-error`}>
            {errors.rut && "No olvides indicar un RUT válido"}
          </div>

          <input
            name="email"
            placeholder="E-Mail"
            type="email"
            defaultValue={props.user && props.user.email}
            className={classnames(`${baseClass}-input`, {
              error: !!errors.email,
              disabled: props.loading,
            })}
            ref={register({ required: true })}
            disabled={props.loading}
          />
          <div className={`${baseClass}-error`}>
            {errors.email && "No olvides indicar un E-Mail válido"}
          </div>

          <input
            type="submit"
            className={classnames(`${baseClass}-input`, `${baseClass}-submit`, {
              disabled: props.loading,
            })}
            value={props.buttonText}
            disabled={props.loading}
            style={{ backgroundColor: props.color }}
          />

          <button
            className={`${baseClass}-input ${baseClass}-submit ${baseClass}-back`}
            onClick={props.onBack}
            disabled={props.loading}
          >
            VOLVER
          </button>
        </form>
      </div>
    </section>
  );
};

export default BaseForm;
