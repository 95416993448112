import React, { useState, useEffect, Suspense, MouseEvent } from "react";

import axios from "axios";
import { useLocalStorage } from "@rehooks/local-storage";

import CouponsList from "../CouponsList";
import CouponsForm from "../CouponsForm";
import CouponsSuccess from "../CouponsSuccess";
import PromptForm from "../PromptForm";

import { Coupon as CouponType, Props as CouponProps } from "../Coupon/types";
import { Props as FormProps } from "../CouponsForm/types";
import { TotemType, UserType } from "./types";

import footer_image from "../../res/images/footer.png"; // Tell Webpack this JS file uses this image
import "./styles.css";

if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://127.0.0.1:8000";
}

const sendPageview = (page: string) => {
  if (typeof ga !== "undefined") {
    ga("set", "page", page);
    ga("send", "pageview");
  }
};

const App = () => {
  const [storedKey, setStoredKey] = useLocalStorage<string>("token", "");
  const [storedTotem, setStoredTotem] = useLocalStorage<TotemType>("totem", {
    title: "MALL",
    logo_url: "",
    color: "white",
    important: "",
    background: {},
    api_token: null,
    analytics: "",
    prompt_user: false,
  });

  const [storedUser, setStoredUser] = useLocalStorage<UserType>("user", {
    rut: "",
    email: "",
  });

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<CouponType[]>([]);
  const [currentScreen, setCurrentScreen] = useState("COUPONS");
  const [selectedCoupon, setSelectedCoupon] = useState<CouponType | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchTotem = async () => {
      if (!storedKey) {
        const result = await axios("/api/totem_session/new");
        const data = result.data;
        setStoredTotem(data);
        setStoredKey(data.api_token);
      }
    };

    fetchTotem();
  }, [storedKey, storedTotem]);

  useEffect(() => {
    const fetchCoupons = async () => {
      if (storedKey) {
        const result = await axios({
          url: "/api/totem_session/coupons",
          headers: {
            Authorization: `Bearer ${storedKey}`,
          },
        });
        const data = result.data;
        setCoupons(data);
      }
    };

    fetchCoupons();
  }, [storedKey]);

  const handlePrintButton = (evt: MouseEvent) => {
    setCurrentScreen("FORM");
    sendPageview("/app/form");
  };

  const handleSelectCoupon: CouponProps["onSelect"] = (coupon, selected) => {
    if (selected) {
      setSelectedCoupon(coupon);
    } else if (selectedCoupon && selectedCoupon.id === coupon.id) {
      setSelectedCoupon(undefined);
    }
  };

  const handleFormSubmit: FormProps["onSubmit"] = async (data) => {
    try {
      setLoadingForm(true);

      let user_data = {
        rut: data.rut,
        email: data.email,
      };

      if (selectedCoupon && selectedCoupon.id) {
        user_data["coupon_id"] = selectedCoupon.id;
      }

      await axios({
        method: "POST",
        url: "/api/totem_session/impressions",
        headers: {
          Authorization: `Bearer ${storedKey}`,
        },
        data: user_data,
      });

      setLoadingForm(false);
      setStoredUser(user_data);

      if (selectedCoupon && selectedCoupon.id) {
        setCurrentScreen("SUCCESS");
        sendPageview("/app/success");
      } else {
        setCurrentScreen("COUPONS");
        sendPageview("/app/set_user");
      }
    } catch (error) {
      setLoadingForm(false);
      sendPageview("/app/error");
      alert(error);
    }
  };

  const handleSuccessBack = () => {
    setSelectedCoupon(undefined);
    setCurrentScreen("COUPONS");
    sendPageview("/app/coupons");
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "COUPONS":
        return (
          <>
            <CouponsList
              coupons={coupons}
              selectedCoupon={selectedCoupon}
              onSelect={handleSelectCoupon}
            />
            {selectedCoupon && (
              <footer className="Footer">
                <div className="Footer-container" onClick={handlePrintButton}>
                  <span className="Footer-selected">1 seleccionado</span>
                  <img alt="Cupones seleccionados" src={footer_image} />
                </div>
              </footer>
            )}
          </>
        );

      case "FORM":
        return (
          <CouponsForm
            totem={storedTotem}
            user={storedUser}
            onSubmit={handleFormSubmit}
            onBack={() => setCurrentScreen("COUPONS")}
            loading={loadingForm}
          />
        );

      case "SUCCESS":
        return (
          <CouponsSuccess
            totem={storedTotem}
            onBack={handleSuccessBack}
            coupon={selectedCoupon}
          />
        );
    }
  };

  const renderHeader = (totem) => {
    return (
      <header className="App-header">
        <div className="Header" style={{ background: totem.color }}>
          {totem.logo_url ? (
            <img alt={totem.title} src={totem.logo_url} />
          ) : (
            <React.Fragment>
              <p className="Header-subtitle">DESCUENTOS EN</p>
              <h1 className="Header-title">{totem.title}</h1>
            </React.Fragment>
          )}
        </div>
      </header>
    );
  };

  return (
    <Suspense fallback={<div>loading...</div>}>
      <div className="App" style={storedTotem.background}>
        {renderHeader(storedTotem)}
        {renderScreen()}
        {storedTotem.prompt_user && storedUser.rut === "" && (
          <PromptForm
            onSubmit={handleFormSubmit}
            onBack={() => setCurrentScreen("COUPONS")}
            loading={loadingForm}
            totem={storedTotem}
          />
        )}
      </div>
    </Suspense>
  );
};

export default App;
