import React from "react";
import BaseForm from "../BaseForm";

import { Props } from "./types";
import "./styles.css";

const PromptForm = (props: Props) => (
  <BaseForm
    color={props.totem.color}
    loading={props.loading}
    onBack={props.onBack}
    onSubmit={props.onSubmit}
    baseClass="PromptForm"
    buttonText="VER DESCUENTOS"
  />
);

export default PromptForm;
