import React, { MouseEvent } from "react";
import classnames from "classnames";

import { Props } from "./types";
import "./styles.css";

const Coupon = (props: Props) => {
  const { coupon } = props;
  const handleClick = (evt: MouseEvent) => {
    evt.preventDefault();
    props.onSelect(coupon, !props.selected);
  };

  const classes = classnames("Coupon", { selected: props.selected });

  return (
    <a href={`/coupon/${coupon.id}`} className={classes} onClick={handleClick}>
      <div className="Coupon-container">
        <div className="Coupon-image">
          <img alt={coupon.description} src={coupon.image_url} />
        </div>
        <div className="Coupon-text">{coupon.short_title}</div>
      </div>
    </a>
  );
};

export default Coupon;
