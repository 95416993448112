import React from "react";
import BaseForm from "../BaseForm";

import { Props } from "./types";
import "./styles.css";

const CouponsForm = (props: Props) => (
  <BaseForm
    color={props.totem.color}
    user={props.user}
    loading={props.loading}
    onBack={props.onBack}
    onSubmit={props.onSubmit}
    buttonText="OBTENER DESCUENTO"
  />
);

export default CouponsForm;
